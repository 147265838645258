export enum FiltersTranslationsKeys {
  ProductSearch_Filter_Age = 'ProductSearch_Filter_Age',
  ProductSearch_Filter_AgeGroup = 'ProductSearch_Filter_AgeGroup',
  ProductSearch_Filter_ArticleType = 'ProductSearch_Filter_ArticleType',
  ProductSearch_Filter_ActivityGroup = 'ProductSearch_Filter_ActivityGroup',
  ProductSearch_Filter_Brand = 'ProductSearch_Filter_Brand',
  ProductSearch_Filter_Catalog = 'ProductSearch_Filter_Catalog',
  ProductSearch_Filter_Category = 'ProductSearch_Filter_Category',
  ProductSearch_Filter_Colour = 'ProductSearch_Filter_Colour',
  ProductSearch_Filter_DeliveryDate = 'ProductSearch_Filter_DeliveryDate',
  ProductSearch_Filter_Division = 'ProductSearch_Filter_Division',
  ProductSearch_Filter_Feature = 'ProductSearch_Filter_Feature',
  ProductSearch_Filter_Fit = 'ProductSearch_Filter_Fit',
  ProductSearch_Filter_Gender = 'ProductSearch_Filter_Gender',
  ProductSearch_Filter_Isd = 'ProductSearch_Filter_Isd',
  ProductSearch_Filter_IsClosedOutProduct = 'ProductSearch_Filter_IsClosedOutProduct',
  ProductSearch_Filter_KeyArticles = 'ProductSearch_Filter_KeyArticles',
  ProductSearch_Filter_LineName = 'ProductSearch_Filter_LineName',
  ProductSearch_Filter_MarketingSupport = 'ProductSearch_Filter_MarketingSupport',
  ProductSearch_Filter_MainColor = 'ProductSearch_Filter_MainColor',
  ProductSearch_Filter_MainDistributionChannel = 'ProductSearch_Filter_MainDistributionChannel',
  ProductSearch_Filter_Material = 'ProductSearch_Filter_Material',
  ProductSearch_Filter_Preset = 'ProductSearch_Filter_Preset',
  ProductSearch_Filter_Product = 'ProductSearch_Filter_Product',
  ProductSearch_Filter_RRP = 'ProductSearch_Filter_RRP',
  ProductSearch_Filter_Season = 'ProductSearch_Filter_Season',
  ProductSearch_Filter_Size = 'ProductSearch_Filter_Size',
  ProductSearch_Filter_Shapelength = 'ProductSearch_Filter_Shapelength',
  ProductSearch_Filter_Subbrand = 'ProductSearch_Filter_Subbrand',
  ProductSearch_Filter_Subcategory = 'ProductSearch_Filter_Subcategory',
  ProductSearch_Filter_Type = 'ProductSearch_Filter_Type',
  ProductSearch_Filter_WSP = 'ProductSearch_Filter_WSP',
  ProductSearch_Filter_Width = 'ProductSearch_Filter_Width',
}
